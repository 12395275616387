
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TemplateFormDetails from '@/components/modals/shared/template/TemplateFormDetails.vue'

export default defineComponent({
  components: {
    TemplateFormDetails,
    TmModal,
    TmButton,
  },
})
